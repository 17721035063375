.ai-ul,.fa-ul{list-style-type:none}
.ai-li,.fa-li{top:.14285714em;text-align:center}
.ai-fw,.ai-li,.fa-fw,.fa-li{text-align:center}
.ai.fa-pull-left,.fa.fa-pull-left{margin-right:.3em}
@font-face{font-family:Academicons;src:url(../fonts/academicons.eot?v=1.8.0);src:url(../fonts/academicons.eot?v=1.8.0) format('embedded-opentype'),url(../fonts/academicons.ttf?v=1.8.0) format('truetype'),url(../fonts/academicons.woff?v=1.8.0) format('woff'),url(../fonts/academicons.svg?v=1.8.0#academicons) format('svg');font-weight:400;font-style:normal}
.ai{display:inline-block;font:normal normal normal 14px/1 Academicons;font-size:inherit;text-rendering:auto;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}
.ai-lg,.fa-lg{line-height:.75em;vertical-align:-15%}
.ai-academia:before{content:"\e800"}
.ai-academia-square:before{content:"\e801"}
.ai-acclaim:before{content:"\e802"}
.ai-acclaim-square:before{content:"\e803"}
.ai-acm:before{content:"\e804"}
.ai-acm-square:before{content:"\e805"}
.ai-ads:before{content:"\e806"}
.ai-ads-square:before{content:"\e807"}
.ai-arxiv:before{content:"\e808"}
.ai-arxiv-square:before{content:"\e809"}
.ai-biorxiv:before{content:"\e810"}
.ai-biorxiv-square:before{content:"\e811"}
.ai-ceur:before{content:"\e812"}
.ai-ceur-box:before{content:"\e813"}
.ai-courser:before{content:"\e814"}
.ai-courser-square:before{content:"\e815"}
.ai-dataverse:before{content:"\e816"}
.ai-dataverse-box:before{content:"\e817"}
.ai-dblp:before{content:"\e818"}
.ai-dblp-square:before{content:"\e819"}
.ai-depsy:before{content:"\e820"}
.ai-depsy-square:before{content:"\e821"}
.ai-doi:before{content:"\e822"}
.ai-doi-square:before{content:"\e823"}
.ai-dryad:before{content:"\e824"}
.ai-dryad-square:before{content:"\e825"}
.ai-figshare:before{content:"\e826"}
.ai-figshare-square:before{content:"\e827"}
.ai-google-scholar:before{content:"\e828"}
.ai-google-scholar-square:before{content:"\e829"}
.ai-ieee:before{content:"\e830"}
.ai-ieee-square:before{content:"\e831"}
.ai-impactstory:before{content:"\e832"}
.ai-impactstory-square:before{content:"\e833"}
.ai-inspire:before{content:"\e834"}
.ai-inspire-square:before{content:"\e835"}
.ai-mendeley:before{content:"\e836"}
.ai-mendeley-square:before{content:"\e837"}
.ai-open-access:before{content:"\e838"}
.ai-open-access-square:before{content:"\e839"}
.ai-orcid:before{content:"\e840"}
.ai-orcid-square:before{content:"\e841"}
.ai-osf:before{content:"\e842"}
.ai-osf-square:before{content:"\e843"}
.ai-overleaf:before{content:"\e844"}
.ai-overleaf-square:before{content:"\e845"}
.ai-philpapers:before{content:"\e846"}
.ai-philpapers-square:before{content:"\e847"}
.ai-piazza:before{content:"\e848"}
.ai-piazza-square:before{content:"\e849"}
.ai-publons:before{content:"\e850"}
.ai-publons-square:before{content:"\e851"}
.ai-pubmed:before{content:"\e852"}
.ai-pubmed-square:before{content:"\e853"}
.ai-researchgate:before{content:"\e854"}
.ai-researchgate-square:before{content:"\e855"}
.ai-scirate:before{content:"\e856"}
.ai-scirate-square:before{content:"\e857"}
.ai-springer:before{content:"\e858"}
.ai-springer-square:before{content:"\e859"}
.ai-zotero:before{content:"\e860"}
.ai-zotero-square:before{content:"\e861"}
.ai-lg{font-size:1.33333333em}
.ai-2x{font-size:2em}
.ai-3x{font-size:3em}
.ai-4x{font-size:4em}
.ai-5x{font-size:5em}
.ai-fw{width:1.28571429em}
.ai-ul{padding-left:0;margin-left:2.14285714em}
.ai-ul>li{position:relative}
.ai-li{position:absolute;left:-2.14285714em;width:2.14285714em}
.ai-li.fa-lg{left:-1.85714286em}
.ai-border{padding:.2em .25em .15em;border:.08em solid #eee;border-radius:.1em}
.ai-pull-left{float:left}
.ai-pull-right{float:right}
.ai.fa-pull-right{margin-left:.3em}
.fa-lg{font-size:1.33333333em}
.fa-2x{font-size:2em}
.fa-3x{font-size:3em}
.fa-4x{font-size:4em}
.fa-5x{font-size:5em}
.fa-fw{width:1.28571429em}
.fa-ul{padding-left:0;margin-left:2.14285714em}
.fa-ul>li{position:relative}
.fa-li{position:absolute;left:-2.14285714em;width:2.14285714em}
.fa-li.fa-lg{left:-1.85714286em}
.fa-border{padding:.2em .25em .15em;border:.08em solid #eee;border-radius:.1em}
.fa-pull-left{float:left}
.fa-pull-right{float:right}
.fa.fa-pull-right{margin-left:.3em}
