#page-404 {
    margin-top: 150px;
	margin-left: auto;
	margin-right: auto;
    text-align: center;
}

#page-404 h1 {
    margin: 30px 0;
    font-size: 4em;
    line-height: 1;
    letter-spacing: -1px;
}
