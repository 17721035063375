$break-mobile-s: 320px;
$break-mobile-l: 425px;

html, body {
  height: 100%;
}

body {
    font-size: 14px;
    font-family: arial, helvetica, sans-serif;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Lucida Grande", verdana, arial, helvetica, sans-serif;
  font-weight: 800;
  line-height: 1.1;
  color: inherit;
}
h1 small, h2 small, h3 small, h4 small, h5 small, h6 small, .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small, h1 .small, h2 .small, h3 .small, h4 .small, h5 .small, h6 .small, .h1 .small, .h2 .small, .h3 .small, .h4 .small, .h5 .small, .h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #999999;
}
h1, .h1, h2, .h2, h3, .h3 {
  margin-top: 17px;
  margin-bottom: 8.5px;
}
h1 small, .h1 small, h2 small, .h2 small, h3 small, .h3 small, h1 .small, .h1 .small, h2 .small, .h2 .small, h3 .small, .h3 .small {
  font-size: 65%;
}
h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 8.5px;
  margin-bottom: 8.5px;
}
h4 small, .h4 small, h5 small, .h5 small, h6 small, .h6 small, h4 .small, .h4 .small, h5 .small, .h5 .small, h6 .small, .h6 .small {
  font-size: 75%;
}
h1, .h1 {
  font-size: 24px;
}
h2, .h2 {
  font-size: 21px;
}
h3, .h3 {
  font-size: 18px;
}
h4, .h4 {
  font-size: 15px;
}
h5, .h5 {
  font-size: 12px;
}
h6, .h6 {
  font-size: 11px;
}


section {
    padding: 40px 0;
}

#wrap {
    min-height: 100%;
}

#main {
    overflow: auto;
    padding-bottom: 130px;

    @media screen and (max-width: $break-mobile-s) {
        padding-bottom: 185px;
    }

    @media screen and (max-width: $break-mobile-l) {
        padding-bottom: 170px;
    }
}

footer {
    color: #777;
    font-size: 85%;
    position: relative;
    margin-top: -90px;
    height: 90px;
    clear: both;
    padding-top: 10px;

    @media screen and (max-width: $break-mobile-s) {
        margin-top: -185px;
        height: 185px;
    }

    @media screen and (max-width: $break-mobile-l) {
        margin-top: -170px;
        height: 170px;
    }
}

#maincontainer {
    a {
        color: #2882dc;
        text-decoration: none;
    }
}

.jumbotron {
    p {
        font-size: 14px;
    }
    padding-top: 10px;
    padding-bottom: 30px;

}


ul.contact-info {
    list-style-type: none;
    margin-bottom: 10px;
    padding-left: 0;
}

ul.contact-info li {
    display: inline;
    margin-right: 7px;
}

.contact-info {
    .fa {
        margin-right: 5px;
        font-size: 15px;
    }
}

.page-heading {
    font-size: 30px;
}

.table {
    tbody > tr > td,  {
        border-top: none;
    }
    thead > tr > th {
        border-bottom: none;
    }
}

img {
	max-width: 100%;
}

.navbar {
     border-radius: 0px;
}

.twitter-tweet {
    margin: auto;
    max-width: 400px;
}

.twitter-moment {
    margin-left: auto;
    margin-right: auto;
    width: 450px !important;
}


.border-freiburg {
	border-left: 5px solid #2c3143 !important;
}
.border-erasmusmc {
	border-left: 5px solid #f17925 !important;
}
.border-deNBI, .border-denbi {
	border-left: 5px solid rgb(0, 174, 236) !important;
}
.border-elixir-it {
        border-left: 5px solid rgb(44,129,187) !important;
}
@import "people";
@import "404";
@import "news";
@import "galaxy_overrides";
@import "carousel";
@import "academicons";

.eu-image-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.eu-image {
    max-height: 100px;
    width: auto;
    padding-left: 1em;
    padding-right: 1em;
}

.multiple-img {
    text-align: center;
}


/* EU default CSS */

.navbar {
	background-color: white !important;
	border: 0px;
}

.navbar .navbar-brand, .navbar .navbar-nav > li > a {
	color: black;
}

.navbar .navbar-brand {
	font-size: 15px;
}

.navbar .navbar-brand:hover, .navbar .navbar-brand:focus,
.navbar .navbar-nav > li > a:hover, .navbar .navbar-nav > li > a:focus {
	color: #003399 !important;
}


body.location-freiburg {
	.navbar {
		background-color: #2c3143 !important;
		border: 0px !important;
	}

	.navbar .navbar-brand, .navbar .navbar-nav > li > a {
		color: white !important;
	}

	.navbar .navbar-brand:hover, .navbar .navbar-brand:focus,
	.navbar .navbar-nav > li > a:hover, .navbar .navbar-nav > li > a:focus {
		color: #aaa !important;
	}

	.nav > li > a:hover, .nav > li > a:focus {
		background-color: transparent !important;
	}

	.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
		background-color: transparent !important;
		color: white;
	}
}

body.location-erasmusmc {
	.navbar {
		background-color: #f17925 !important;
		border: 0px !important;
	}

	.navbar .navbar-brand, .navbar .navbar-nav > li > a {
		color: black !important;
	}

	.navbar .navbar-brand:hover, .navbar .navbar-brand:focus,
	.navbar .navbar-nav > li > a:hover, .navbar .navbar-nav > li > a:focus {
		color: white !important;
	}

	.nav > li > a:hover, .nav > li > a:focus {
		background-color: transparent !important;
	}

	.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
		background-color: transparent !important;
		color: black;
	}
}

body.location-belgium {
	.navbar {
		background-color: #1b2944 !important;
		border: 0px !important;
	}

	.navbar .navbar-brand, .navbar .navbar-nav > li > a {
		color: white !important;
	}

	.navbar .navbar-brand:hover, .navbar .navbar-brand:focus,
	.navbar .navbar-nav > li > a:hover, .navbar .navbar-nav > li > a:focus {
		color: #aaa !important;
	}

	.nav > li > a:hover, .nav > li > a:focus {
		background-color: transparent !important;
	}

	.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
		background-color: transparent !important;
		color: white;
	}
}

body.location-pasteur {
	.navbar {
		background-color: #7591BE !important;
		border: 0px !important;
	}

	.navbar .navbar-brand, .navbar .navbar-nav > li > a {
		color: white !important;
	}

	.navbar .navbar-brand:hover, .navbar .navbar-brand:focus,
	.navbar .navbar-nav > li > a:hover, .navbar .navbar-nav > li > a:focus {
		color: #aaa !important;
	}

	.nav > li > a:hover, .nav > li > a:focus {
		background-color: transparent !important;
	}

	.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
		background-color: transparent !important;
		color: white;
	}
}

body.location-elixir-it {
        .navbar {
                background-color: #2C81BB !important;
                border: 0px !important;
        }

        .navbar .navbar-brand, .navbar .navbar-nav > li > a {
                color: white !important;
        }

        .navbar .navbar-brand:hover, .navbar .navbar-brand:focus,
        .navbar .navbar-nav > li > a:hover, .navbar .navbar-nav > li > a:focus {
                color: #aaa !important;
        }

        .nav > li > a:hover, .nav > li > a:focus {
                background-color: transparent !important;
        }

        .nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
                background-color: transparent !important;
                color: white;
        }
}

.tooltable {
    td:nth-child(1) {
        width: 15%;
    }

    td:nth-child(3) {
        width: 15%;
    }
}

.ont-workflow-table {
    border: 1px solid #dfe2e5;
    tbody > tr > td,  {
        border: 1px solid #dfe2e5;
        padding: .6em 1em;
    }
}

.rna-intro-right {
    width: 35%;
    min-width: 100px;
    max-height: 200px;
    float: right;
    padding-left: 20px;
    padding-bottom: 10px;
}

.sc-intro-left {
    min-width: 50px;
    max-height: 100px;
    float: left;
    padding-right: 20px;
    padding-bottom: 10px;
}

.virology-image-left {
    width: 25%;
    float: left;
    padding-right: 20px;
    padding-bottom: 5px;
}

.virology-image-right {
    width: 20%;
    float: right;
    padding-left: 20px;
    padding-bottom: 5px;
}

.tag-element {
  color: white !important;
}
